<template>
  <div class="wrapper">
    <!-- 人员评估 -->
    <!-- <div>
        <el-button type="text" icon="el-icon-plus" @click="()=>{isplus = true }" v-if="!isplus"></el-button>
        <el-button type="text" icon="el-icon-minus" @click="()=>{isplus = false }" v-if="isplus"></el-button>
        <el-button type="primary" icon="el-icon-plus" @click="addProject()" v-if="isplus">添加</el-button>
      </div> -->
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="seachStatus" status="my-orange" @click="showSearch(false)">隐藏查询</vxe-button>
        <vxe-button v-if="!seachStatus" status="my-orange" icon="fa fa-search" @click="showSearch(true)">查询</vxe-button>
        <!-- <vxe-button status="my-orange" icon="" @click="goPage('add')">下载报告</vxe-button> -->
      </template>
    </vxe-toolbar>
    <div v-if="seachStatus" class="search">
      <vxe-form
        title-align="right"
        title-width="120"
        title-colon
        :data="searchData"
        @submit="searchEvent"
        @reset="searchReset"
      >
        <vxe-form-item title="姓名" span="8">
          <vxe-input v-model="searchData.assessorName" placeholder="请输入姓名" clearable />
        </vxe-form-item>
        <vxe-form-item title="身份证号" span="8">
          <vxe-input v-model="searchData.oldPersonIdNum" placeholder="请输入身份证号" clearable />
        </vxe-form-item>
        <vxe-form-item title="评估机构" span="8">
          <vxe-input v-model="searchData.assessOrgId" placeholder="请输入评估机构" clearable />
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="oldPersonName" label="姓名" />
        <el-table-column prop="oldPersonIdNum" label="身份证号" />
        <el-table-column prop="assessOrgName" label="评估机构" />
        <el-table-column prop="plan" label="评估方案" />
        <el-table-column prop="finalEvalResult" label="评估结果" />
        <el-table-column prop="assessType" label="评估类型" />
        <el-table-column prop="assessTime" label="评估时间" />
        <el-table-column prop="source" label="来源" />
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button v-permission="'PersonAssessDetail'" type="text" size="small" @click="handleClick(scope.row)">查看</el-button>
            <el-button
              v-if="scope.row.ismore"
              type="text"
              size="small"
              class="el-icon-minus"
              @click="scope.row.ismore = false"
            />
            <el-button
              v-if="!scope.row.ismore"
              type="text"
              size="small"
              class="el-icon-plus"
              @click="scope.row.ismore = true"
            />
            <!-- <el-button @click="editClick(scope.row)" v-if="scope.row.ismore" type="text" size="small" >编辑</el-button> -->

            <el-button v-if="scope.row.ismore" v-permission="'PersonAssessDeleteButton'" type="text" size="small" @click="delProject(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="添加人员评估" :visible.sync="dialogFormVisible" :show-close="false" :close-on-click-modal="false">
      <el-form :model="formData" label-position="left">
        <el-form-item label="姓名" prop="serviceOldPersonId" :label-width="formLabelWidth">
          <el-select v-model="formData.oldPersonId" filterable style="width:100%" placeholder="请选择改造对象">
            <el-option v-for="item in personList" :key="item.id" :label="item.personName" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="评估机构" :label-width="formLabelWidth">
          <el-select v-model="formData.assessOrgId" filterable placeholder="请选择服务机构" style="width:100%">
            <el-option v-for="item in orgDatas" :key="item.id" :label="item.compName" :value="item.id" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="类型" :label-width="formLabelWidth">
          <el-input v-model="formData.name" autocomplete="off"></el-input>
          </el-form-item> -->
        <el-form-item label="评估方案" :label-width="formLabelWidth">
          <el-input v-model="formData.plan" autocomplete="off" />
        </el-form-item>
        <el-form-item label="评估员姓名" :label-width="formLabelWidth">
          <el-input v-model="formData.assessorName" autocomplete="off" />
        </el-form-item>
        <el-form-item label="感觉与沟通等级" :label-width="formLabelWidth">
          <el-select
            v-model="formData.measureResults.feelLevel"
            placeholder="请选择等级"
            style="width:100%"
            @change="changeLevel()"
          >
            <el-option v-for="item in levelArr" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="精神状态" :label-width="formLabelWidth">
          <el-select
            v-model="formData.measureResults.mindLevel"
            placeholder="请选择等级"
            style="width:100%"
            @change="changeLevel()"
          >
            <el-option v-for="item in levelArr" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="日常生活活动" :label-width="formLabelWidth">
          <el-select
            v-model="formData.measureResults.lifeLevel"
            placeholder="请选择等级"
            style="width:100%"
            @change="changeLevel()"
          >
            <el-option v-for="item in levelArr" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="社会参与" :label-width="formLabelWidth">
          <el-select
            v-model="formData.measureResults.societyLevel"
            placeholder="请选择等级"
            style="width:100%"
            @change="changeLevel()"
          >
            <el-option v-for="item in levelArr" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="初步评估结果" :label-width="formLabelWidth">
          <el-input v-model="formData.preEvalResult" autocomplete="off" disabled />
        </el-form-item>
        <el-form-item label="最终评估结果" :label-width="formLabelWidth">
          <el-input v-model="formData.finalEvalResult" autocomplete="off" disabled />
        </el-form-item>
        <el-form-item label="审核意见" :label-width="formLabelWidth">
          <el-input v-model="formData.reviewComments" autocomplete="off" />
        </el-form-item>
        <el-form-item label="专家意见" :label-width="formLabelWidth">
          <el-input v-model="formData.expertComments" autocomplete="off" />
        </el-form-item>
        <el-form-item label="评估类型" :label-width="formLabelWidth">
          <el-input v-model="formData.expertComments" autocomplete="off" />
        </el-form-item>
        <el-form-item label="来源" :label-width="formLabelWidth">
          <el-input v-model="formData.source" autocomplete="off" />
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="() => {
            dialogFormVisible = false,
            formData = {
              measureResults: {}
            }
          }"
        >取 消</el-button>
        <el-button type="primary" @click="addElderBtn()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 分页 -->
    <div class="page">
      <vxe-pager
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :page-sizes="tablePage.pageSizes"
        :total="tablePage.totalResult"
        :layouts="layouts"
        @page-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'
export default {
  data() {
    return {
      isplus: false,
      searchData: {},
      seachStatus: false,
      tableData: [],
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      dialogFormVisible: false,
      formLabelWidth: '120px',
      formData: {
        oldPersonId: '',
        assessOrgId: '',
        plan: '',
        assessorName: '',
        preEvalResult: '',
        finalEvalResult: '',
        reviewComments: '',
        expertComments: '',
        measureResults: {
          feelLevel: '',
          mindLevel: '',
          lifeLevel: '',
          societyLevel: ''
        }
      },
      orgDatas: [],
      personList: [],
      isedit: false,
      levelArr: [
        { name: '0级', value: 0 },
        { name: '1级', value: 1 },
        { name: '2级', value: 2 },
        { name: '3级', value: 3 }

      ]
    }
  },
  created() {
    this.getLists()
    this.querySumCenterList()
    this.getPersonList()
  },
  computed: {
    ...mapState([
      'layouts'
    ])
  },
  methods: {
    ...mapActions([
      'assessList',
      'getServeOrgsListServe',
      'getHomePersonListServe',
      'addAssess',
      'updateAssess',
      'deleteAssess'
    ]),
    // 获取服务对象
    getPersonList() {
      const params = {
        current: 1,
        size: 4000,
        param: {}
      }
      this.getHomePersonListServe(params).then(res => {
        if (res.code == 200) {
          this.personList = res.data.records
        }
      })
    },
    // 查看
    handleClick(row) {
      this.$router.push({
        name: 'PersonAssessDetail',
        query: {
          id: row.id
        }
      })
    },
    // 删除
    delProject(row) {
      this.$XModal.confirm('您确定要删除该数据?').then(type => {
        if (type === 'confirm') {
          const params = row.id
          this.deleteAssess(params).then(res => {
            console.log(res)
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.getLists()
            }
          })
        } else {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        }
      })
    },
    querySumCenterList() {
      this.getServeOrgsListServe({
        current: 1,
        size: 9999,
        param: {}
      }).then(res => {
        // console.log(res,'querySumCenterList');
        if (res.code == 200) {
          this.orgDatas = res.data.records
          console.log(this.orgDatas)
        }
        this.loading = false
      })
    },
    // 添加确定
    addElderBtn() {
      if (this.isedit) {
        const params = {
          oldPersonId: this.formData.oldPersonId,
          assessOrgId: this.formData.assessOrgId,
          plan: this.formData.plan,
          assessorName: this.formData.assessorName,
          preEvalResult: this.formData.preEvalResult,
          finalEvalResult: this.formData.finalEvalResult,
          reviewComments: this.formData.reviewComments,
          expertComments: this.formData.expertComments,
          measureResults: JSON.stringify(this.formData.measureResults)

        }
        this.updateAssess(params).then(res => {
          if (res.code == 200) {
            this.$message.success('修改成功')
            this.formData = {
              oldPersonId: '',
              assessOrgId: '',
              plan: '',
              assessorName: '',
              preEvalResult: '',
              finalEvalResult: '',
              reviewComments: '',
              expertComments: '',
              measureResults: {
                feelLevel: '',
                mindLevel: '',
                lifeLevel: '',
                societyLevel: ''
              }
            }
            this.dialogFormVisible = false
            this.getLists()
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        // let params = this. formData
        const params = {
          oldPersonId: this.formData.oldPersonId,
          assessOrgId: this.formData.assessOrgId,
          plan: this.formData.plan,
          assessorName: this.formData.assessorName,
          preEvalResult: this.formData.preEvalResult,
          finalEvalResult: this.formData.finalEvalResult,
          reviewComments: this.formData.reviewComments,
          expertComments: this.formData.expertComments,
          measureResults: JSON.stringify(this.formData.measureResults)

        }
        this.addAssess(params).then(res => {
          if (res.code == 200) {
            console.log(res, '添加')
            this.$message.success('添加成功')
            this.formData = {
              oldPersonId: '',
              assessOrgId: '',
              plan: '',
              assessorName: '',
              preEvalResult: '',
              finalEvalResult: '',
              reviewComments: '',
              expertComments: '',
              measureResults: {
                feelLevel: '',
                mindLevel: '',
                lifeLevel: '',
                societyLevel: ''
              }
            }
            this.dialogFormVisible = false
            this.getLists()
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    searchEvent() {
      this.getLists()
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    searchReset() {
      this.searchData = {}
      this.getLists()
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    // 查询列表
    getLists() {
      const params = {
        assessOrgId: this.searchData.assessOrgId,
        assessorName: this.searchData.assessorName,
        oldPersonIdNum: this.searchData.oldPersonIdNum,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      }
      this.assessList(params).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.tableData = res.data.dataList
          this.tablePage.totalResult = res.data.total
          for (let i = 0; i < this.tableData.length; i++) {
            this.$set(this.tableData[i], 'ismore', false)
          }
        }
      })
    },
    // 新增
    addProject() {
      console.log(123)
      this.dialogFormVisible = true
    },
    changeLevel() {
      console.log('等级')
      const feel = this.formData.measureResults.feelLevel
      const mind = this.formData.measureResults.mindLevel
      const life = this.formData.measureResults.lifeLevel
      const society = this.formData.measureResults.societyLevel
      console.log(feel, mind, life, society)
      if (feel !== '' && mind !== '' && life !== '' && society !== '') {
        if (life === 0) {
          if (feel === 0 && mind === 0 && (society === 0 || society === 1)) {
            this.formData.preEvalResult = '能力完好'
            this.formData.finalEvalResult = '能力完好'
          } else if ((mind >= 1 || feel >= 1) || society >= 2) {
            this.formData.preEvalResult = '轻度失能'
            this.formData.finalEvalResult = '轻度失能'
          }
        } else if (life === 1) {
          if (mind <= 1 || feel <= 1 || society <= 1) {
            this.formData.preEvalResult = '轻度失能'
            this.formData.finalEvalResult = '轻度失能'
          } else if ((mind === 2 && feel === 2 && society === 2)) {
            this.formData.preEvalResult = '中度失能'
            this.formData.finalEvalResult = '中度失能'
          } else if (mind === 3 || feel === 3 || society === 3) {
            this.formData.preEvalResult = '中度失能'
            this.formData.finalEvalResult = '中度失能'
          }
        } else if (life === 2) {
          if (mind === 2 && feel === 2 && society === 2) {
            this.formData.preEvalResult = '重度失能'
            this.formData.finalEvalResult = '重度失能'
          } else if (mind === 3 || feel === 3 || society === 3) {
            this.formData.preEvalResult = '重度失能'
            this.formData.finalEvalResult = '重度失能'
          } else if (mind <= 2 || feel <= 2 || society <= 2) {
            this.formData.preEvalResult = '中度失能'
            this.formData.finalEvalResult = '中度失能'
          }
        } else if (life === 3) {
          this.formData.preEvalResult = '重度失能'
          this.formData.finalEvalResult = '重度失能'
        }
      }
    }
  }

}
</script>

<style>
.w-100 {
  width: 100% !important;
}
</style>
